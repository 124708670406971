<script setup lang="ts">
import { useFieldSelection } from '@register/components/Review/composables'
import {
  useCurrentLeaseDocument,
  type ILeaseDocumentFieldGroup,
} from '@register'

const { lease } = useCurrentLeaseDocument()

// Field selection
const { fieldSelectionState, snippetRowPosition, unselect, isSelectedGroup } =
  useFieldSelection()

const groups = computed(() => {
  return (
    lease.value.groups?.filter((group) =>
      group.sections?.some((section) =>
        section.fields?.some((field) => field.isRequired),
      ),
    ) ?? []
  )
})

// TODO: fix types
const getFieldsByGroup = (group: ILeaseDocumentFieldGroup) => {
  return (group.sections ?? [])
    .flatMap(({ fields }) => fields ?? [])
    .filter((f) => !!f.isRequired)
}
</script>

<template>
  <div>
    <div class="flex w-full">
      <h2 class="w-full text-white">Fast Approval</h2>
    </div>

    <div v-for="group in groups" :key="group.id" class="mt-4">
      <h3 class="w-full font-semibold text-white">{{ group.name }}</h3>

      <div class="mt-2 grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2">
        <LazyLeaseReviewField
          v-for="field in getFieldsByGroup(group)"
          :key="field.id"
          :field="field"
        />

        <!-- Field snippet -->
        <LazyLeaseReviewPdfSnippet
          v-if="isSelectedGroup(group)"
          :state="fieldSelectionState"
          :row-position="snippetRowPosition"
          @close="unselect"
        />
      </div>
    </div>
  </div>
</template>
